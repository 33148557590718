import recallServices from '../../../../../services/supplier/supplier-recall'
import recallNoteService from '../../../../../services/provider/recall-notes'
import DocumentViewModel from '../../../../../components/part/shared/DocumentViewModel'
import { VueEditor } from 'vue2-editor'
import { COMMUNICATION_TYPE, SUPPLIERREPORTS } from '../../../../../misc/commons'
import workflowServices from '../../../../../services/work-flow'
export default {
    props: {
        communicationId: { type: String, default: null },
        providerCommunicationId: {type: String, default: ''},
        supplierRecallId: {type: String, default: ''},
        communicationType: {type: String, default: ''},
    },
    components: {
        VueEditor,
        DocumentViewModel,
    },
    data() {
        return {
            histories: [],
            item: '',
            item1: '',
            activeIndex: 0,
            recall: COMMUNICATION_TYPE.RECALL,
            reports: SUPPLIERREPORTS,
            filteredReports: [],
        }
    },
    created() {
        recallServices.getRecallById(this.communicationId, this.communicationType).then(resp => {
            if (resp.error){
                this.$router.push({ name: 'supp_Recall' })
            } else {
                this.item = resp.data.d
                this.getReportHistory(this.reports[0].value)
            }
        })
    },
    methods: {
        getReportHistory(selectedReport) {
            recallServices.GetReportHistories(this.communicationId,selectedReport).then(resp => {
                if (!resp.error) {
                    this.histories = resp.data.d
                }
            })
        },
        addReportDownloadHistory(selectedReport) {
            recallServices.AddReportDownloadHistory(this.communicationId,selectedReport).then(resp => {
                if (!resp.error) {
                    this.getReportHistory(selectedReport)
                }
            }
            )
        },
        activateCol(index, val) {
            this.activeIndex = index
            this.getReportHistory(val)
        },
        isActive(index) {
            return this.activeIndex === index
        },
        downloadCSVReport(val){
            if (val == 'Notification Report') {
                this.exportNotificationReport()
            }
            else if (val == 'Provider Status') {
                this.exportProviderStausReport()
            }
            else if (val == 'Delegate Status'){
                this.exportDelegateStatusReportCSV()
            }
            else if (val == 'Recall Event Status by Location')
                this.exportLocationStatusReport()
        },
        downloadXLSXReport(val){
            if (val == 'FDA Effectiveness Report') {
                this.downloadFDAReportFile()
            }
            else if (val == 'Notification Report') {
                this.downloadNotificationReport()
            }
            else if (val == 'Provider Status') {
                this.downloadProviderStausReport()
            }
            else if (val == 'Delegate Status'){
                this.downloadDelegateStatusReport()
            }
            else if (val == 'Change History') {
                this.downloadChangeHistoryXLSXReport()
            }
            else if (val == 'Recall Event Status by Location') {
                this.downloadLocationStausReport()
            }
        },
        downloadFDAReportFile()
        {
            recallServices.downloadFDAReportFile({
                communicationId: this.communicationId,
                communicationUID: this.item.communicationUID
            }).then(resp => {
                this.addReportDownloadHistory('FDA Effectiveness Report')
                window.open(resp.data.d, '_blank')
            })
        },
        exportNotificationReport() {
            recallServices.exportAuditReport(this.communicationId, this.item.communicationUID).then(resp => {(
                this.addReportDownloadHistory('Notification Report')
            )}
            )
        },
        downloadNotificationReport() {
            recallServices.downloadAuditReport(this.communicationId, this.item.communicationUID).then(resp => {(
                this.addReportDownloadHistory('Notification Report')
            )}
            )
        },
        exportProviderStausReport() {
            recallServices.exportproviderstausReport(this.communicationId, this.item.communicationUID).then(resp => {
                this.addReportDownloadHistory('Provider Status')
            })
        },
        downloadProviderStausReport() {
            recallServices.downloadproviderstausReport(this.communicationId, this.item.communicationUID).then(resp => {
                this.addReportDownloadHistory('Provider Status')
            })
        },
        exportLocationStatusReport(){
            recallServices.exportproviderLocationstausReport(this.communicationId, this.item.communicationUID).then(resp => {
                this.addReportDownloadHistory('Recall Event Status by Location')
            })
        },
        downloadLocationStausReport() {
            recallServices.downloadproviderLocationstausReport(this.communicationId, this.item.communicationUID).then(resp => {
                this.addReportDownloadHistory('Recall Event Status by Location')
            })
        },
        downloadDelegateStatusReport() {
            workflowServices
                .downloadProviderDelegateStausReport(this.communicationId, this.item.communicationUID,0,this.item.recallsitename,this.communicationType,'xlsx')
                .then((resp) => {
                    this.addReportDownloadHistory('Delegate Status')
                })
        },
        exportDelegateStatusReportCSV() {
            workflowServices
                .downloadProviderDelegateStausReport(this.communicationId, this.item.communicationUID,0,this.item.recallsitename,this.communicationType,'csv')
                .then((resp) => {
                    this.addReportDownloadHistory('Delegate Status')
                })
        },
        downloadChangeHistoryXLSXReport() {
            recallServices.downloadChangeHistoryXLSXReport(this.communicationId, this.supplierRecallId).then(resp => {
                if (resp && resp.error) {
                    this._showToast('Recall change history download failed.', { variant: 'danger' })
                }
                else {
                    this.addReportDownloadHistory('Change History')
                }
            })
        },
    }
}